import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpErrorResponse } from "@angular/common/http";
import { catchError } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { NbToastRef, NbToastrService } from "@nebular/theme";
@Injectable()
  export class ErrorInterceptor implements HttpInterceptor {
    constructor(private toastrService: NbToastrService) {}
    intercept(req: HttpRequest<any>, next: HttpHandler) {
      if(req.url.indexOf('notification') !== -1) {
        return next.handle(req);
      }
      else {
        return next.handle(req).pipe(
          catchError((error: HttpErrorResponse)=> {
            let errorMessage = 'An Unknown Error has occured..!'
            let errorStatus:number;
            if(error.error.response){
              errorStatus = error.status; 
              errorMessage = error.error.response
            }
            else if(error.error.msg){
              errorStatus = error.status; 
              errorMessage = error.error.msg
            }
            else if(error.error && error.status!==500){
              errorStatus = error.status; 
              errorMessage = error.error
            }
          //  else if(error.status === 500) {
          //     errorStatus = error.status;
          //     errorMessage = 'Sorry, we had some technical problems during your last operation.'
          // }
  
            this.showToast(errorMessage, errorStatus, {
              duration: 5000, status:'danger',icon:'alert-triangle-outline', position:'bottom-right'
            });
            return throwError(error)
          })
        );
      }

    }

    private showToast(message: string, title: any, config) {
        const toastRef: NbToastRef = this.toastrService.show(
            title, message, config);
    }
  }
