import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { LayoutService } from '../../../@core/utils';
import { map, takeUntil, filter } from 'rxjs/operators';
import { interval, Subject, Subscription } from 'rxjs';
import { AuthService } from '../../../@core/services/auth.service';
import { Router } from '@angular/router';
import { Restaurant } from 'src/app/@core/models/restaurant';
import { PageMenuService } from 'src/app/pages/page-menu.service';
import { RestaurantService } from 'src/app/@core/services/restaurant.service';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { NotificationDialogComponent } from '../notification-dialog/notification-dialog.component';
import { FoodItemLocalService } from 'src/app/@core/services/food-item-local.service';



@Component({
  selector: 'rom-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {
  activeRestaurant: Restaurant
  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;
  userInfo:String;
  userRestaurants: Restaurant[] = [];
  selectedResturant;
  submitServerAttempted: boolean = true;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [ { title: 'Profile' }, { title: 'Log Out' } ];

  notifications= []
  notification$: Subscription;

  showNotificationBadge: boolean

  constructor(private sidebarService: NbSidebarService,
              private menuService: NbMenuService,
              private themeService: NbThemeService,
              private layoutService: LayoutService,private foodItemLocalService: FoodItemLocalService,
              private pageMenuService: PageMenuService,    private restaurantService: RestaurantService,
              private breakpointService: NbMediaBreakpointsService,
              private authService: AuthService,
              private router: Router,
              private notificationService: NotificationService,
              private dialogService: NbDialogService) {
  }

  ngOnInit() {
    this.getUserRestaurantsData();
    this.currentTheme = this.themeService.currentTheme;
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
      
      this.menuService.onItemClick()
      .pipe(
        filter(({ tag }) => tag === 'my-context-menu'),
        map(({ item: { title } }) => title),
      )
      .subscribe(title => {
        if(title==='Log Out')
        this.authService.logOut();
        else{
          let activeUser = JSON.parse(localStorage.getItem('activeUser'));      
          if(!this.hasAccessToViewNonStaffUser()){
            this.router.navigate(["/non-staff-user-profile"], {
              queryParams: {
                email: activeUser['email'],
                restaurant_id: this.authService.getActiveRestaurant().objectId
              }
            });
          }else{
            this.router.navigate(["/user-profile"], {
              queryParams: {
                email: activeUser['objectId'],
                restaurant_id: this.authService.getActiveRestaurant().objectId
              }
            });

            // this.router.navigate(["/user/profile/"+activeUser['objectId']], {
            // });
          }
        }

      });
      this.pageMenuService.isUpdateRestaurant.subscribe((val)=>{
        this.userRestaurants = [];
        this.selectedResturant = "";
        this.getUserRestaurantsData();
      })
  
     this.userInfo=localStorage.getItem("userInfo");
     this.activeRestaurant = JSON.parse(localStorage.getItem("activeRestaurant"))
    // this.getUserNotifications();
  
  }

  ngOnDestroy() { 
    // this.notification$.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
    

  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();

    return false;
  }

  hasAccessToViewNonStaffUser(): boolean {
    return this.authService.currentUser.isStaffUser(
      Object.keys(
        this.authService.currentUser.rolePermissions[
          this.authService.getActiveRestaurant().objectId
        ]
      )[0]
    );
  }

  onChangeResturant(restaurantName) {
   var selectedResturant: Restaurant[] = this.userRestaurants.filter((res) => {
      return res.name == restaurantName;
    });
    this.authService.setActiveRestaurant(selectedResturant[0]);
    if(this.canViewFoodItems()){
      this.foodItemLocalService.getFoodItemsFromAPIAndSave();
    }
    this.pageMenuService.setCheckUserInfo(true);
    setTimeout(()=> location.reload(), 2000 )
  }

  canViewFoodItems(): boolean {
    return this.authService.currentUser.canViewFoodItems(
      this.authService.getActiveRestaurant().objectId
    );
  }

  
  private fillUserRestaurantsArray(valObjArray) {
    for (let valObj of valObjArray) {
      var restaurant = new Restaurant();
      restaurant.fillValuesFromObj(valObj);
      if (restaurant.status == 'Approved') {
        this.userRestaurants.push(restaurant);
      }
    } 
  }

  private getUserRestaurantsData() {
    this.submitServerAttempted = true;
    this.restaurantService
      .getRestaurants(this.authService.currentUser.restaurants)
      .subscribe(
        (response) => {
          this.fillUserRestaurantsArray(response);
          this.submitServerAttempted = false;
          this.setActiveRestaurant();
        },
        (error) => {
          this.submitServerAttempted = false;
        }
      );
  }
  private setActiveRestaurant() {
    let activeRestaurant = JSON.parse(localStorage.getItem('activeRestaurant'));
    if (activeRestaurant && activeRestaurant !== null) {
      this.selectedResturant = activeRestaurant.name;
    }
  }

  // private getUserNotifications(){
  //  this.notification$ = interval(3*1000).subscribe(()=> {
  //     this.notificationService.getNotification().subscribe((res: any[])=>{
  //       this.notifications = res
  //       this.showNotificationBadge=this.isNotificationBadge(this.notifications)
  //     })
  //   })

  // }

  isNotificationBadge(notifications: Array<any>): boolean{
    var value = notifications.every((notification) =>{
        return notification.read === true
    })    
    return !value
  }

  openNotificationDialog(item) {
    if(!item.action) {
      this.notificationService.changeNotificationReadStatus(item.objectId).subscribe(res=>{ 
        // this.getUserNotifications()
      })
      this.dialogService.open(NotificationDialogComponent, {closeOnBackdropClick: false, context: {
        notification: item,
      }})
    }

    
  }

}
