<nb-card status="primary" class="dialog-card">
    <nb-card-header>
      <div class="d-flex justify-content-between">
        <div>{{notification?.type | titlecase}}</div>
        <div><nb-icon nbButton status="danger" icon="close-outline" (click)="dismiss()"></nb-icon></div>
      </div>
    </nb-card-header>
    <nb-card-body>
        <p class="text-center">{{notification?.message}}</p>
        <p class="text-center text-muted" *ngIf="notification.description">{{notification?.description}}</p>
        <h5 class="text-center" *ngIf="notification.username"> Username: <span class="text-primary">{{notification?.username}}</span></h5>
        <h5 class="text-center" *ngIf="notification.password"> Password (OTP): <span class="text-primary">{{notification?.password}}</span></h5>
        <h5 class="text-center" *ngIf="notification.percentage"> Percentage: <span class="text-primary">{{notification?.percentage}}</span></h5>
        <h5 class="text-center" *ngIf="notification.lastLoginDate"> Last access date: <span class="text-primary">{{notification?.lastLoginDate}}</span></h5>
        <h5 class="text-center" *ngIf="notification.endDate"> Time remaining: <span class="text-primary">{{PACTimer}}</span></h5>
      <div class="text-center">
        <button *ngIf="notification.typeId==='1'" nbButton hero status="success" class="mr-2" (click)="onClickYes()">Yes</button>
        <button *ngIf="notification.typeId==='2'" nbButton hero status="success" class="mr-2" [nbPopover]="userProfileStatisticsConfirmPopover">Confirm</button>
        <button *ngIf="notification.typeId==='1'" nbButton hero status="danger"  [nbPopover]="createPACredentialsPopover">No</button>
        <button *ngIf="notification.typeId==='2'" nbButton hero status="danger" [nbPopover]="userProfileStatisticsNoPopover">No</button>
        <button *ngIf="notification.typeId==='4'" nbButton hero status="primary" class="mr-2" (click)="getFHCFile(notification.sender, notification.restaurantId)">View certificate</button>
        <button *ngIf="notification.typeId==='4'" nbButton hero status="success" class="mr-2" (click)="updateFHC(true)">Approve</button>
        <button *ngIf="notification.typeId==='4'" nbButton hero status="danger" [nbPopover]="FHCRejectPopover">Reject</button>
      </div>
    </nb-card-body>
  </nb-card>

  <ng-template #createPACredentialsPopover>
    <nb-card class="mb-0">
      <nb-card-header>Send Popeyes Academy Credentials</nb-card-header>
      <nb-card-body>
      <div class="form-group">
        <label class="label">Username</label>
        <input name="quantity" type="text" nbInput fullWidth #username="ngModel" [(ngModel)]="PACUsername" id="username" name="username"
           required placeholder="Enter username">
           <div *ngIf="username.dirty && username.invalid" class="text-warning">
            <div *ngIf="username.errors?.required">
              Username is required
            </div>
          </div>
      </div>
      <div class="form-group">
        <label class="label">Password</label>
        <nb-form-field>
          <input [type]="getInputType()" nbInput fullWidth #password="ngModel" [(ngModel)]="PACUserPassword" id="password" name="password"
          required minlength="6" autocomplete="new-password" placeholder="Enter password">
          <button nbSuffix nbButton ghost (click)="toggleShowPassword()">
            <nb-icon [icon]="showPassword ? 'eye-outline' : 'eye-off-2-outline'"
                     pack="eva"
                     [attr.aria-label]="showPassword ? 'hide password' : 'show password'">
            </nb-icon>
          </button>
        </nb-form-field>
        <div *ngIf="password.dirty && password.invalid" class="text-warning">
          <div *ngIf="password.errors?.required">
            Password is required
          </div>
          <div *ngIf="password.errors?.minlength">
            Password should be 6 characters long
          </div>
        </div>
      </div>
      </nb-card-body>
      <nb-card-footer>
        <div class="text-center">
          <button nbButton hero status="primary" (click)="onClickNo()">Send Details</button>
        </div>
      </nb-card-footer>
    </nb-card>
  </ng-template>  
  
<ng-template #userProfileStatisticsConfirmPopover>
    <nb-card class="mb-0">
      <nb-card-header>
        Upload User Profile Statistics 
      </nb-card-header>
      <nb-card-body>
        <input
        type="file"
        #fileDropRef
        id="{{ name }}"
        accept="application/pdf"
        (change)="fileChangeEvent($event)"
      />
      </nb-card-body>
    <nb-card-footer>
      <button type="submit" [disabled]="!userProfileStatisticsFile" nbButton hero status="primary" (click)="uploadUserProfileStatisticsFile()">submit</button>
    </nb-card-footer>
    </nb-card>
</ng-template>

<ng-template #userProfileStatisticsNoPopover>
  <nb-card class="mb-0">
    <nb-card-header>User Profile Statistics</nb-card-header>
    <nb-card-body>
    <div class="form-group">
      <label class="label">Percentage</label>
      <input name="percentage" type="text" nbInput fullWidth #percentage="ngModel" [(ngModel)]="userProfileStatisticsPercentage" id="percentage" name="percentage"
      pattern="^(0*(\d{1,2}(\.\d+)?)|\.\d+|100(\.0+$)?)$" required NumbersOnly placeholder="Percentage">
      <div *ngIf="percentage.dirty && percentage.invalid" class="text-warning">
        <div *ngIf="percentage.errors?.required">
          Percentage is required
        </div>
        <div *ngIf="percentage.errors?.pattern">
          Please enter a value between 0 and 100
        </div>
      </div>
    </div>
    <div class="form-group">
      <label class="label">Last login date (DD-MM-YYYY)</label>
      <input type="date" nbInput fullWidth #loginDate="ngModel" [(ngModel)]="userProfileStatisticsLastLoginDate" id="loginDate" name="loginDate"
      placeholder="Last login date" required>
      <div *ngIf="loginDate.dirty && loginDate.invalid" class="text-warning">
        <div *ngIf="loginDate.errors?.required">
          Last login date is required
        </div>
      </div>
    </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="text-center">
        <button nbButton hero status="primary" [disabled]="percentage.invalid || loginDate.invalid" (click)="uploadUserProfileStatistics()">Submit</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #FHCRejectPopover>
  <nb-card class="mb-0">
    <nb-card-body>
    <div class="form-group">
      <textarea nbInput fullWidth placeholder="Please give reason..." name="comment" #txt="ngModel"
      class="text-error" [(ngModel)]="FHCRejectMessage" rows="4" required></textarea>
    <div *ngIf="txt.touched && txt.invalid" class="text-warning">
      <div *ngIf="txt.errors?.required">
        Comments is required
      </div>
    </div>
    </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="text-center">
        <button nbButton hero status="primary" (click)="updateFHC(false)">Submit</button>
      </div>
    </nb-card-footer>
  </nb-card>
</ng-template>