import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router, CanActivate, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RestaurantGuard implements CanActivate{

  constructor(private authService: AuthService, private router: Router) { }

  canActivate(route, state: RouterStateSnapshot) {
    const restaurantUser = !this.authService.currentUser.isAugmenifyUser();
    if(!restaurantUser){
      this.router.navigate(['/restaurant/dashboard'])
    }
    return restaurantUser
  }
}