
export class Restaurant {

    objectId;
    address: RestaurantAddress
    name;
    brandName;
    status;
    users;

    weekStartingDay;
    operatingHours;

    operatingCurrency;
    timeZone;
    payrollDate;
    payrollCost;
    maxBudget;
    margin;
    contactNumber;
    termsCondition;
    breakTimeEntry: RestaurantBreakTimeData[];
    minWageAbove_18;
    minWageBelow_18;

    constructor() {
        this.objectId = ""; this.address = { city: "", country: "", postalCode: "", province: null, streetName: "", streetNumber: "", unit: "" }
        this.name = ""; this.brandName = ""; this.weekStartingDay = ""; this.margin = "";
        this.status = ""; this.payrollDate = ""; this.termsCondition = ""; this.maxBudget = "";
        this.breakTimeEntry = []; this.users = []; this.payrollCost = "",
            this.operatingCurrency = ""; this.timeZone = ""; this.contactNumber = "";
        this.minWageAbove_18 = ""; this.minWageBelow_18 = "";
        this.initiateRestaurantOperatingHours();


    }

    fillValuesFromObj(valObj) {
        for (var key of Object.keys(this)) {
            if (valObj[key] != "") {
                this[key] = valObj[key];
            }
        }
    }



    private initiateRestaurantOperatingHours() {
        this.operatingHours = [
            {
                day: 'monday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'tuesday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'wednesday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'thursday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'friday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'saturday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'sunday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
        ];
    }



    static getDayOptions() {
        return ["sunday", "monday", "tuesday", "wednesday", "thursday",
            "friday", "saturday"];
    }


    static getTimeZoneOptions() {
        return ["America/Toronto"]
    }


    static getCurrencyOptions() {
        return ["USD", "CAD", "INR"];
    }

    static getCountryProvinceOptions() {
        let payload = [
            {
                id: 1,
                country: "Canada",
                provinces: ["Alberta", "British Columbia", "Manitoba", "New Brunswick",
                    "Newfoundland and Labrador", "Northwest Territories", "Nova Scotia", "Nunavut",
                    "Ontario", "Prince Edward Island", "Quebec", "Saskatchewan", "Yukon"]

            },
            {
                id: 2,
                country: "USA",
                provinces: ["California", "Texas", "Florida", "Georgia", "Alaska", "Washington", "Hawaii",
                    "Pennsylvania", "Arizona", "Colorado", "Michigam", "Massachus", "Ohio", "Indiana",
                    "New Jersey", "Minnesota", "lllinos", "North Carolina", "Oregon", "Virginia", "New York",
                    "Maine", "Utah", "Maryland", "Alabama", "Tennessee", "Wisconsin", "Montana", "Missouri", "Nevada",
                    "Mississippi", "Louisiana", "Kentucky", "South Carolina", "Connecticut", "New Mexico", "Kansas",
                    "Lowa", "Wyoming", "Arkansas", "Delaware", "Nebraska", "Idaho", "Vermont", "New Hampshire", "Rhode Isiand",
                    "West Virginia", "South Dakota", "North Dakota", "Oklahoma"]
            },
            {
                id: 3,
                country: "India",
                provinces: ["Kerala", "Uttar Pradesh", "Maharashtra", "Tamil Nadu", "Punjab", "Karnataka", "Bihar", "Jammu and Kashmir",
                    "West Bengal", "Rajasthan", "Gujarat", "Andhra Pradesh", "Odisha", "Assam", "Telangana", "Haryana", "Madhya Pradesh",
                    "Himachal Pradesh", "Goa", "Manipur", "Uttarakhand", "Jharkhand", "Nagaland", "Sikkim", "Arunachal Pradesh", "Chhattisgarth",
                    "Meghalaya", "Tripura", "Mizoram"
                ]
            }
        ]
        return payload
    }


    static getsortedWeekDay(weekdays: Object, weekStartingDay: string) {
        let sortedDays = [];
        let sortedWeekDays = {};
        let days = Restaurant.getDayOptions();
        for (let i = days.indexOf(weekStartingDay); i < days.length; i++)
            sortedDays.push(days[i])
        for (let i = 0; i < days.indexOf(weekStartingDay); i++)
            sortedDays.push(days[i])
        sortedDays.forEach((day) => {
            for (let key in weekdays) {
                if (weekdays.hasOwnProperty(key)) {
                    if (day === key) {
                        sortedWeekDays[day] = weekdays[day]
                    }
                }
            }
        })
        return sortedWeekDays;
    }
    static permissionList() {
        return ['show in schedule sheet', 'show in time entry sheet'];
    }
}
export class RestaurantBreakTimeData {
    cutOffTimeVar: any;
    breakTimeVar: any;

    constructor() {
        this.cutOffTimeVar = "";
        this.breakTimeVar = "";
    }

}

export class RestaurantAddress {
    city: string;
    country: string;
    postalCode: string;
    province: string;
    streetName: string;
    streetNumber: string;
    unit: string;


}