import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { Observable, Subject } from 'rxjs';
import { Restaurant } from '../@core/models/restaurant';
import { User } from '../@core/models/user';

@Injectable({
  providedIn: 'root'
})
export class PageMenuService {

  public isCheckUpdatedUserInfo: Subject<any>;
  public isCheckSubscription: Subject<any>;
  public isUpdateRestaurant: Subject<any>;

  constructor() {
    this.isCheckUpdatedUserInfo = new Subject();
    this.isCheckSubscription = new Subject();
    this.isUpdateRestaurant = new Subject();
  }

  public setSubscrpition(value: any) {
    this.isCheckSubscription.next(value);
  }

  public getsetSubscrpition(): Observable<any> {
    return this.isCheckSubscription.asObservable();
  }

  public setCheckUserInfo(value: boolean) {
    this.isCheckUpdatedUserInfo.next(value);
  }

  public getsetCheckUserInfo(): Observable<any> {
    return this.isCheckUpdatedUserInfo.asObservable();
  }

  public setUpdateRestaurant(value: boolean) {
    this.isUpdateRestaurant.next(value);
  }

  public getUpdateRestaurant(): Observable<any> {
    return this.isUpdateRestaurant.asObservable();
  }



  menuItems() {
    let MENU_ITEMS: NbMenuItem[] = [
      {
        title: 'Dashboard',
        icon: 'home-outline',
        link: '/dashboard',
        hidden: this.isVisible(['dashboard'])
      },
      {
        title: 'FEATURES',
        group: true,
      },
      {
        title: 'Restaurant',
        icon: 'map-outline',
        hidden: this.isVisible(['availbility','create restaurant','update restaurant']),
        children: [
          {
            data: "create restaurant",
            title: 'Request New Location',
            link: '/new-location-request',
            hidden: this.isVisible(['create restaurant'])
          },
          {
            data: "update restaurant",
            title: 'Update Restaurant',
            link: '/new-location-request/edit',
            hidden: this.isVisible(['update restaurant'])
          },
          {
            title: 'Operating Hours',
            link: '/availbility',
            hidden: this.isVisible(['availbility'])
          },
        ]
      },
      {
        title: 'Subscription',
        icon: 'npm-outline',
        hidden: this.isVisible(['create subscription','view subscription','cancel subscription','update subscription']),
        children: [
          {
            data: "view subscription",
            title: 'Subscription',
            link:'/subscription/subscription-management',
            hidden: this.isVisible(['view subscription','cancel subscription'])
          },
          {
            data: "create subscription",
            title: 'Products',
            link:'/subscription/products',
            hidden: this.isVisible(['create subscription'])
          }
        ]
      },
      {
        title: 'Sales Entry',
        icon: 'layout-outline',
        hidden: this.isVisible(['add sales entry', 'view sales entry', 'update sales entry']),
        children: [
          {
            data: "view sales entry",
            title: 'Sales Dashboard',
            link: '/sales',
            hidden: this.isVisible(['view sales entry', 'update sales entry'])
          },
          {
            data: "add sales entry",
            title: 'Add Sales Entry',
            link: '/sales/entry',
            hidden: this.isVisible(['add sales entry'])
          },
        ]
      },
      {
        title: 'Schedule',
        icon: 'calendar-outline',
        hidden: this.isVisible(['create schedule', 'view schedule', 'update schedule']),
        children: [
          {
            title: 'Create Schedule',
            link: '/create-schedule',
            hidden: this.isVisible(['create schedule'])
          },
          {
            title: 'View Schedule',
            link: '/view-schedule',
            hidden: this.isVisible(['view schedule', 'update schedule'])
          },
          {
            title: 'View Comment',
            link: '/comment-schedule',
            hidden: this.isVisible(['view comment'])
          }
        ]
      },
      {
        title: 'Time Entry',
        icon: 'clock-outline',
        hidden: this.isVisible(['create timesheet', 'view timesheet', 'update timesheet']),
        children: [
          {
            title: 'Add Time Entry',
            link: '/time/entry',
            hidden: this.isVisible(['create timesheet'])
          },
          {
            data: 'view timesheet',
            title: 'View Time Entry',
            link: '/view-time-entry',
            hidden: this.isVisible(['view timesheet', 'update timesheet'])
          },
        ]
      },
      {
        title: 'User Management',
        icon: 'people-outline',
        hidden: this.isVisible(['view user','create user', 'update user','verify user','feedback']),
        children: [
          {
            title: 'Users',
            link: '/user-dashboard',
            hidden: this.isVisible(['view user','update user','verify user'])
          },
          {
            title: 'Users feedback',
            link: '/user-feedback',
            hidden: this.isVisible(['feedback'])
          },
          {
            title: 'Add User',
            link: '/user/new',
            hidden: this.isVisible(['create user'])
          },
        ]
      },
      {
        title: 'Food Item Management',
        icon: 'shopping-bag-outline',
        link: '/food-item-management',
        hidden: this.isVisible(['view food item', "create food item", "update food item", "delete food item",])
      },
      {
        title: 'Assets Management',
        icon: 'link-2-outline',
        link: '/asset/dashboard',
        hidden: this.isVisible(['create asset', 'view asset', 'update asset'])
      },
      {
        title: 'Vendor Management',
        icon: 'car-outline',
        link: '/vendor/dashboard',
        hidden: this.isVisible(['create vendor', 'view vendor'])
      },
      {
        title: 'Payroll',
        icon: 'file-text-outline',
        link: '/payroll',
        hidden: this.isVisible(['view payrollsheet'])
      },
      {
        title: 'Report',
        icon: 'activity-outline',
        link: '/report',
        hidden: this.isVisible(['view report'])
      },
      {
        title: 'Reporting Structure',
        icon: 'award-outline',
        link: '/reporting-structure',
        hidden: this.isVisible(['view report'])
      },
      {
        title: 'Complaint Logs',
        icon: 'inbox-outline',
        link: '/complaint-log/dashboard',
        hidden: false
      },
    ];
    return MENU_ITEMS
  }


  private isVisible(menuItems): boolean {
    let visible: boolean = true;
    let activeUser: User = JSON.parse(localStorage.getItem('activeUser'));
    let activeRestaurant: Restaurant = JSON.parse(localStorage.getItem('activeRestaurant'));
    let userPermissions: any = (Object.values(activeUser.rolePermissions[activeRestaurant.objectId])[0]);
    let userPermissionsRole: any = (Object.keys(activeUser.rolePermissions[activeRestaurant.objectId])[0]);
    let statusForSubscription = localStorage.getItem('status')
    if(statusForSubscription === 'inactivated'){
      if(userPermissionsRole === 'owner'){
          menuItems.forEach(menuItem => {
            userPermissions.forEach(permission => {
               if ('create subscription' === menuItem){
                  visible = false;
                }
              });
          });
      }
    }else{
      if (activeUser.status === 'Approved') {
        if(userPermissionsRole === 'owner' || userPermissionsRole === 'general manager' || userPermissionsRole === 'manager'){
          menuItems.forEach(menuItem => {
            userPermissions.forEach(permission => {
              if (menuItem === permission || menuItem === 'feedback' || menuItem === 'settings' || menuItem ==='availbility' || menuItem === 'dashboard')
                visible = false;
            });
          });
        }else{
          menuItems.forEach(menuItem => {
            userPermissions.forEach(permission => {
              if (menuItem === permission || menuItem ==='availbility' || menuItem === 'dashboard')
                visible = false;
            });
          });

        }
      }
    }
    return visible;
  }


}
