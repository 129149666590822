<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <div class="d-flex flex-column">
      <div>
        <a class="logo">Augmenify<span class="text-success"><strong>.</strong></span></a>
      </div>
      <div>
         <nb-select fullWidth class="brand-name" status="primary" *ngIf="activeRestaurant" placeholder="Switch Resturant" size="small" hero shape="semi-round"
            [selected]="selectedResturant" (selectedChange)="onChangeResturant($event)">
            <nb-option *ngFor="let restaurantItem of userRestaurants; let i = index" [value]="restaurantItem.name">
              {{restaurantItem.name}}
            </nb-option>
          </nb-select>  
      </div>
    </div>
      <nb-select class="theme ml-5" [selected]="currentTheme" (selectedChange)="changeTheme($event)">
        <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
      </nb-select>
    </div>
</div>

<div class="header-container">
  <nb-actions size="small">
    <!-- <nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action> -->
    <!-- <nb-action class="control-item" icon="email-outline"></nb-action> -->
    <nb-action class="control-item" [nbPopover]="notificationPopover" nbPopoverTrigger="hover" nbPopoverPlacement="bottom" icon="bell-outline" [badgeDot]="showNotificationBadge" badgeStatus="primary"
    badgePosition="top right">
    </nb-action>
    <nb-action class="user-action">
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="userInfo"
               
               nbContextMenuTag="my-context-menu">
      </nb-user>
    </nb-action>
  </nb-actions>
</div>

<ng-template #notificationPopover>
  <nb-card class="popover-card">
    <nb-card-header>Notifications</nb-card-header>
    <p *ngIf="notifications.length === 0" class="p-2 text-success">No notifications yet!</p>
    <nb-list *ngIf="notifications.length > 0">
      <nb-list-item *ngFor="let item of notifications" [ngClass]="{'read': !item.read}" style="cursor: pointer" (click)="openNotificationDialog(item)">
        <nb-user size="small" [name]="item.message" [title]="item.createdDate" showInitials="false">
        </nb-user>
      </nb-list-item>
    </nb-list>
  </nb-card>
</ng-template>

