import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { AppError } from '../errors/app-error';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FoodItem } from '../models/food-item';
import { UnauthorisedAccessError } from '../errors/unathorised-access-error';

@Injectable({
  providedIn: 'root'
})
export class FoodItemService {

  private url = environment.api_prefix + "/food_item";


  constructor(private authService: AuthService, private http: HttpClient) { 

  }


  createFoodItem(foodItemData: FoodItem){
    foodItemData.convertSizesToHaveOnlyString();

    var payload = {
      restaurant_id : this.authService.getActiveRestaurant().objectId,
      name : foodItemData.name,
      sizes : foodItemData.sizes
    };

    return this.http.post(this.url + "/create", payload, 
        this.authService.getBasicAuthHeaderForAPI())
        .pipe(
          catchError(this.handleError)
        );
  }


  updateFoodItem(foodItemData: FoodItem){
    foodItemData.convertSizesToHaveOnlyString();

    var payload = {
      restaurant_id : this.authService.getActiveRestaurant().objectId,
      name : foodItemData.name,
      sizes : foodItemData.sizes,
      object_id: foodItemData.objectId
    };

    return this.http.post(this.url + "/update", payload, 
        this.authService.getBasicAuthHeaderForAPI())
        .pipe(
          catchError(this.handleError)
        );

  }


  getFoodItemsForRestaurant(restaurant) {
    return this.http.get(this.url + "/restaurant/" + restaurant + "/all", 
        this.authService.getBasicAuthHeaderForAPI());
  }


  deleteFoodItem(foodItemId){
    var payload = {
      object_id: foodItemId
    }

    return this.http.post(this.url + "/delete", payload,
        this.authService.getBasicAuthHeaderForAPI())
        .pipe(
          catchError(this.handleError)
        );
  }


  handleError(error: HttpErrorResponse){
    console.log(error);
    if(error.status === 403)
      return throwError(new UnauthorisedAccessError(error));
    return throwError(new AppError(error));
  }


}
