import { Component } from '@angular/core';

@Component({
  selector: 'rom-footer',
  styleUrls: ['./footer.component.scss'],
  template: `
    <span class="created-by">
      <b><a href="https://www.augmenify.com/" target="_blank">Copyright © Fusioneh {{currentYear}}</a></b>
    </span>
    <div class="socials">
    <b><a href="https://www.linkedin.com/company/augmenify/" target="_blank"><nb-icon icon="linkedin-outline"></nb-icon></a></b>
    </div>
  `,
})
export class FooterComponent {
  currentYear: number = new Date().getFullYear()
}
