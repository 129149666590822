import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { InvalidLoginError } from '../errors/invalid-login-error';
import { UnauthorisedAccessError } from '../errors/unathorised-access-error';
import { AppError } from '../errors/app-error';
import { catchError } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})

export class NotificationService {
  private url = environment.api_prefix + '/notification';

  constructor(private authService: AuthService, private http: HttpClient) {}


  getNotification() {
    return this.http.get(
      this.url,
      this.authService.getBasicAuthHeaderForAPI()
    );
  }

  changeNotificationReadStatus(notificationId: string) {
    var payload = {
      object_id: notificationId
    };
  
    return this.http
      .post(
        this.url + '/is_read',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  changeNotificationActionStatus(notificationId: string) {
    var payload = {
      object_id: notificationId
    };
  
    return this.http
      .post(
        this.url + '/action',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.status === 422) return throwError(new InvalidLoginError(error));
    if (error.status === 403)
      return throwError(new UnauthorisedAccessError(error));
    if (error.status === 500) return throwError(new AppError(error));
    if (error.status === 401)
      return throwError(new UnauthorisedAccessError(error));
    return throwError(new AppError(error));
  }
}