
class FoodItemSize{
    name;
    price;

    constructor(){
        this.name = "";
        this.price = "";
    }
}


export class FoodItem {
    name ;
    restaurantId;
    sizes : FoodItemSize[];
    objectId;

    constructor(numSizes){
        this.name = "";
        this.restaurantId = "";
        this.objectId = "";
        this.createSizesArray(numSizes);
    }


    fillValuesFromObj(valObj){
        for(var key of Object.keys(this)){
            if(valObj[key]!=""){
              this[key] = valObj[key];
            }
        }
    }

    private createSizesArray(numSizes){
        this.sizes = new Array(numSizes);
        for(var i=0; i< numSizes; i++){
            this.sizes[i] = new FoodItemSize();
        }
    }


    createNewSizesArray(numSizes){
        this.createSizesArray(numSizes);
    }


    updateForSingleSize(price){
        this.sizes = new Array(1);
        this.sizes[0] = new FoodItemSize();
        this.sizes[0].name = "DEFAULT";
        this.sizes[0].price = price;
    }


    convertSizesToHaveOnlyString(){
        for(let sizeOption of this.sizes){
            sizeOption.price = String(sizeOption.price);
        }
    }
    
}