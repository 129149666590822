
export class CommonUtils{

    public static camelToSnakeCase(val: string){
        var result = val.replace( /([A-Z])/g, " $1" );
        return result.split(' ').join('_').toLowerCase();
    }


    public static isImageFile(fileName :string) : boolean{
        var ext = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
        return ext == 'png' || ext == 'jpeg' || ext == 'jpg' || ext == 'pdf';
    }

    public static convertDateToUTC(date: Date) { 
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds()); 
      }

      public static objFilter(data, keys_to_keep: Array<string>) {
        const redux = array => array.map(o => keys_to_keep.reduce((acc, curr) => {
            acc[curr] = o[curr];
            return acc;
          }, {}));
        return redux(data)  
      }
    
}