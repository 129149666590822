import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'hourMin'
})
export class HourMinutesPipe implements PipeTransform {

  transform(value: number): string { 
      var m = Math.abs(value) % 60;
      var h = (Math.abs(value)-m)/60;
      return (value < 0?"-":"") + (h<10?"0":"") + h.toString() + ":" + (m<10?"0":"") + m.toString();
    
  }
}