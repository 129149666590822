import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'sortByWeekStartingDay'
})
export class SortSchedulePipe implements PipeTransform {
    transform(schedule: Array<any>, weekStartingDay: string) {
        let transformSchedule: Array<any> = []
        let indexOfWeekStartingDate = schedule.findIndex((x) => x.day === weekStartingDay.toLowerCase())
        for (let i = indexOfWeekStartingDate; i < schedule.length; i++) {
            transformSchedule.push(schedule[i])
        }
        for (let i = 0; i < indexOfWeekStartingDate; i++) {
            transformSchedule.push(schedule[i])
        }
        return transformSchedule
    }
}