import { FoodItemService } from './food-item.service';
import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { FoodItem } from '../models/food-item';

@Injectable({
  providedIn: 'root'
})
export class FoodItemLocalService {

  localFoodItemsVar = "foodItems";

  constructor(private authService: AuthService, 
              private fooditemService: FoodItemService) { }

  createLocalFoodItems(){
    if(!localStorage.getItem(this.localFoodItemsVar)){   
      this.getFoodItemsFromAPIAndSave();     
    }
  }


   getFoodItemsFromAPIAndSave(){
    this.fooditemService.getFoodItemsForRestaurant(this.authService.getActiveRestaurant().objectId)
        .subscribe(response => {
          var foodItems = response;         
          foodItems = this.formatFoodItemsForLocal(foodItems);
          this.saveFoodItemsLocal(this.localFoodItemsVar, foodItems);
        })
  }


   getFoodItemsFromAPI(){
    this.fooditemService.getFoodItemsForRestaurant(this.authService.getActiveRestaurant().objectId)
      .subscribe(response =>{
           var foodItems = response;
           localStorage.setItem(this.localFoodItemsVar,JSON.stringify(foodItems));
      })
  }
  


  private formatFoodItemsForLocal(foodItems){
    return foodItems;
  }


  private saveFoodItemsLocal(localStorageVar, foodObj){
    localStorage.setItem(localStorageVar, JSON.stringify(foodObj));
  }

  updateForItemChange(){
    this.getFoodItemsFromAPIAndSave();
  }

  get foodItems() : FoodItem[]{   
    var foodItemList = JSON.parse(localStorage.getItem(this.localFoodItemsVar));
    var foodItemObjList : FoodItem []= [];
    for(var foodItem of foodItemList){
      var foodItemObj = new FoodItem(0);
      foodItemObj.fillValuesFromObj(foodItem);
      foodItemObjList.push(foodItemObj);
    }
    return foodItemObjList
  }

}
