import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError } from 'rxjs';
import { UnauthorisedAccessError } from '../errors/unathorised-access-error';
import { AppError } from '../errors/app-error';
import { catchError } from 'rxjs/operators';
import { Restaurant } from '../models/restaurant';
import { CommonUtils } from './common-utils';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {

  private url = environment.api_prefix + "/restaurant";


  constructor(private authService: AuthService, private http: HttpClient) {

  }


  getRestaurants(restaurantObjectIds) {
    var payload = {
      restaurants: restaurantObjectIds
    };

    return this.http.post(this.url + "/view/all", payload,
      this.authService.getBasicAuthHeaderForAPI())
      .pipe(
        catchError(this.handleError)
      );
  }


  getAllRestaurnats() {
    return this.http.get(this.url + "/get_all_restaurants",
      this.authService.getBasicAuthHeaderForAPI())
      .pipe(
        catchError(this.handleError)
      );
  }


  approveRestaurant(restaurantId) {
    var payload = new FormData();

    payload.append("object_id", restaurantId);

    return this.http.post(this.url + "/approve", payload,
      this.authService.getBasicAuthHeaderForAPI())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateOperatingHours(restaurant: Restaurant) {
     return this.http.post(this.url + "/update_operating_hours", restaurant,
      this.authService.getBasicAuthHeaderForAPI())
      .pipe(
        catchError(this.handleError)
      );
  }
  
  updateRestaurantData(userRestaurantData) {
    var payload = userRestaurantData;
    return this.http.post(this.url + "/update_restaurant", payload,
      this.authService.getBasicAuthHeaderForAPI())
      .pipe(
        catchError(this.handleError)
      );
  }

  getValidRestaurantData() {
    var payload = {"restaurants": this.authService.currentUser.restaurants}
    return this.http.post(this.url + "/view/valid", payload,
    this.authService.getBasicAuthHeaderForAPI())
    .pipe(
      catchError(this.handleError)
    );
  }

  handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.status === 403)
      return throwError(new UnauthorisedAccessError(error));
    return throwError(new AppError(error));
  }

}
