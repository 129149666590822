<nb-card>
    <nb-card-header>
        <div class="float-right">
            <nb-icon nbButton status="danger" icon="close-outline" (click)="dismiss()"></nb-icon>
         </div>
    </nb-card-header>
    <nb-card-body>
        <pdf-viewer [src]="pdfSrc"
        [render-text]="true"
        [original-size]="false"
        [autoresize]="true"
        style="width: 400px; height: 500px">
        </pdf-viewer>
    </nb-card-body>
</nb-card>