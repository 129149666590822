import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { NbDialogService } from '@nebular/theme';

@Component({
  selector: 'rom-operating-hour',
  templateUrl: './operating-hour.component.html',
  styleUrls: ['./operating-hour.component.scss']
})
export class OperatingHourComponent implements OnInit {
  @Input() days!: any;
  @Input() title!: string;
  @Input() readonly!: boolean;
  constructor(private dialogService: NbDialogService) { }

  invalidPeriodMsg: string;

  ngOnInit(): void {
  }

  open(dialog: TemplateRef<any>, day: string, shift: string, index: number) {
    if(!this.readonly) {
      this.dialogService.open(dialog, { context: {
        day: day,
        shift: shift,
        period: shift==='night' ? this.days[index].period[0] : this.days[index].period[1]
      } });
    }

  }


  onClickDelete(period:{open: string, close: string}, ref) {
    period.open = "";
    period.close = "";
    ref.close();
  }

  onSave(day: string, shift:number, open:string, close:string, dialog) {
    if(this.isValidPeriod({open, close})){
      let index = this.days.findIndex(x => x.day===day)
      this.days[index].period[shift].open = open;
      this.days[index].period[shift].close = close;
      dialog.close();
    }
  }

  isValidPeriod(period: {open: string, close: string}): boolean {
    if(period.open==""){
      this.invalidPeriodMsg = "Shift start should not be empty";
      return false;
    }
    else if(period.close==""){
      this.invalidPeriodMsg = "Shift end should not be empty";
      return false;
    }
    else if(period.open > period.close && period.open && period.close){
      this.invalidPeriodMsg = "Shift start time is greater than shift end time";
      return false;
    }
      
    else if(period.open == period.close && period.open!=="" && period.close!=="") {
      this.invalidPeriodMsg = "Shift start time must not equal to shift end time" ;
      return false;
    } 
    else {
      this.invalidPeriodMsg = null; 
      return true
    }
        
  }

  onModelChange() {
    if(this.invalidPeriodMsg!="")
      this.invalidPeriodMsg = null
  }

}
