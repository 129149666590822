import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'rom-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss']
})
export class FileUploadComponent {
  @Input() name: string;
  @Input() type: string;
  @Output() onChangeEvent = new EventEmitter<File>();
  
  fileName: string;
  fileError: string;
  private readonly fileSize = 1000000;
  fileChangeEvent($event) {
    var file: File = $event.target.files[0];

    if (file) {
      if(file.type != 'application/pdf') {
        this.fileError = "Accepts PDF only";
        this.onChangeEvent.emit(null);
        return;
      }
      if (file.size > this.fileSize) {
        this.fileError = 'Size should be less than 1 MB';
        this.onChangeEvent.emit(null);
        return;
      }
      this.fileError = null;
      this.fileName = file.name;
      this.onChangeEvent.emit(file);
    }
  }
}
