import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Restaurant } from '../models/restaurant';
import { User } from '../models/user';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private router: Router) { 
   
  }

  saveToken(token){
   if(token){
      localStorage.setItem("token", token);
    }
  }
 

  isLoggedIn(){
    let jwtHelper = new JwtHelperService();
    let token = localStorage.getItem("token");    
    if(jwtHelper.isTokenExpired(token))
      localStorage.clear();
    return !jwtHelper.isTokenExpired(token)
  }


  get currentUser() : User{
    let token = localStorage.getItem("token");
    var user = new User();
    user.fillValsFromTokenObject(new JwtHelperService().decodeToken(token));
    return user;    
  }


  logOut(){
    localStorage.clear();
    this.router.navigate(["/auth/login"]);
  }


  getBasicAuthHeaderForAPI(){
    let token = localStorage.getItem("token");
    return {headers: new HttpHeaders({"Authorization": "Bearer " + token })};    
  }


  setActiveRestaurant(restaurant : Restaurant){
    localStorage.setItem("activeRestaurant", JSON.stringify(restaurant));
  }


  getActiveRestaurant() : Restaurant{
    var restaurant = new Restaurant();
    var storedActiveRestaurant = localStorage.getItem("activeRestaurant");
    if(storedActiveRestaurant == null){
      return restaurant;
    }
    var valObj = JSON.parse(localStorage.getItem("activeRestaurant"));
    restaurant.fillValuesFromObj(valObj);
    return restaurant;
  }

}
