export class User {
    firstName;
    lastName;
    email: String;
    restaurants;
    rolePermissions: {};
    status;
    availability;


    constructor(){
        this.firstName = ""; this.lastName = ""; this.email = "";
        this.restaurants =[]; this.rolePermissions = {}; this.status = "";
        this.initiateAvailability(); 
    }


    fillValsFromTokenObject(userObj){
       
        this.assignRestaurants(userObj);
        this.assignUsername(userObj);
        this.assignRolePermissions(userObj);
        this.status = userObj["user_claims"]["status"];
    }
    
    private assignRestaurants(userObj){
        
        this.restaurants = userObj["user_claims"]["restaurants"];
    }


    private assignUsername(userObj){
        this.email = userObj["identity"];
    }


    private assignRolePermissions(userObj){
        this.rolePermissions = userObj["user_claims"]["rolePermissions"];
    }


    fillValsFromObj(valObj){
        for(var key of Object.keys(this)){
            if(valObj[key]!=""){
              this[key] = valObj[key];
            }
        }
    }

    private initiateAvailability() {
        this.availability = [
            {
                day: 'monday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'tuesday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'wednesday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'thursday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'friday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'saturday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
            {
                day: 'sunday',
                period: [{ open: "00:00", close: "10:00" }, { open: "10:00", close: "23:59" }]
            },
        ];
    }


    static getDayOptions(): Array<string> {
        return ["sunday", "monday", "tuesday", "wednesday", "thursday",
            "friday", "saturday"];
    }

    public isAugmenifyUser() : boolean{
        return this.email.includes("augmenify.com");
    }


    public isApprovedUser() : boolean {
        return this.status == "Approved";
    }


    private hasPermission(restaurantId, task) : boolean{
        var restaurnatRolePermissions = this.rolePermissions[restaurantId];
        var currentRestaurantRole = Object.keys(restaurnatRolePermissions)[0];
        return restaurnatRolePermissions[currentRestaurantRole].indexOf(task) > -1;
    }


    // ******************* Sales Entry Access Astractions ******************
    hasSalesEntryAccess(restaurantId) : boolean{
        return this.hasPermission(restaurantId, "add sales entry") ||
        this.hasPermission(restaurantId, "update sales entry") ||
        this.hasPermission(restaurantId, "view sales entry");
    }


    canAddSalesEntry(restaurantId) : boolean{
        return this.hasPermission(restaurantId, "add sales entry");
    }


  /**
  * // TODO: comment canViewSalesEntry
  * To check user has access/not access to view sales entry.
  * @param restaurantId
  * @returns boolean
  */
    canViewSalesEntry(restaurantId) : boolean{
        return this.hasPermission(restaurantId, "view sales entry") || 
        this.hasPermission(restaurantId, "update sales entry") || 
        this.hasPermission(restaurantId, "create schedule") || 
        this.hasPermission(restaurantId, "update schedule");
    }

    canUpdateSalesEntry(restaurantId) : boolean {
        return this.hasPermission(restaurantId, "update sales entry");
    }
    
    canCreateSchedule(restaurantId) : boolean{
        return this.hasPermission(restaurantId, "create schedule");
    }

    canUpdateSchedule(restaurantId) : boolean {
        return this.hasPermission(restaurantId, "update schedule");
    }

    canUpdateTimeEntry(restaurantId) : boolean {
        return this.hasPermission(restaurantId, "update timesheet");
    }

    canViewDashboardChart(userRole: string): boolean {
        let role = ["owner", "general manager"];
        return role.includes(userRole);
    }

    isOwner(userRole: string): boolean {
        let role = ["owner"];
        return role.includes(userRole);
    }
    
    isStaffUser(userRole: string): boolean {
        let role = ["owner","general manager", "manager", "shift-super", "cashier", "cook", "dishwasher"];
        return role.includes(userRole);
    }


    // ******************* User management Access Astractions ******************
    hasUserManagementAccess(restaurantId) : boolean{
        return this.hasPermission(restaurantId, "create user") ||
        this.hasPermission(restaurantId, "update user");
    }


    canAddUser(restaurantId) : boolean {
        return this.hasPermission(restaurantId, "create user")
    }


    canUpdateUser(restaurantId) : boolean {
        return this.hasPermission(restaurantId, "update user")
    }
    
    canVerifyUser(restaurantId) : boolean {
        return this.hasPermission(restaurantId, "verify user")
    }
    
    canApproveUser(restaurantId) : boolean {
        return this.hasPermission(restaurantId, "view user")
    }

    canViewFoodItems(restaurantId): boolean {
        return this.hasPermission(restaurantId, "view food item")
    }
    
    canAddFoodItem(restaurantId):boolean{
        return this.hasPermission(restaurantId, "create food item")
    }
    
    canUpdateFoodItem(restaurantId):boolean{
        return this.hasPermission(restaurantId, "update food item")
    }
    
    canDeleteFoodItem(restaurantId):boolean{
        return this.hasPermission(restaurantId, "delete food item")
    }

    canUpdateDetailsOfUser(user:User, restaurantId) : boolean{
        return this.canUpdateUser(restaurantId) &&
            this.isUserAboveOtherUserInRestaurant(user, restaurantId);
    }


    private isUserAboveOtherUserInRestaurant(user: User, restaurantId) : boolean{
        var roleHierarchy = ["owner", "manager", "shift-super", "cashier", "cook"]
        var currentUserRole = Object.keys(this.rolePermissions[restaurantId])[0];
        var otherUserRole = Object.keys(user.rolePermissions[restaurantId])[0];
        var currentUserHierarchyIndex = roleHierarchy.indexOf(currentUserRole);
        var otherUserHierarchyIndex = roleHierarchy.indexOf(otherUserRole);
        return currentUserHierarchyIndex < otherUserHierarchyIndex;
    }

    canUpdateLocation(restaurantId) : boolean {
        return this.hasPermission(restaurantId, "update location")
    }
    

    // ******************* Restaurant Access Astractions ******************
    canRequestNewLocation(restaurantId) : boolean{
        return this.hasPermission(restaurantId, "create restaurant");
    }

}

export class NonStaffUser {
    firstName;
    middleName;
    lastName;
    email;
    cellNumber;
    permissions;
    companyName:string;
    companyAddress:string;
    contactName:string;
    contactNumber;
    isThirdpartyEmployee:boolean;
    isErrorCompanyAndContactName?: boolean;
    isErrorContactNumber?: boolean;

    constructor(){
        this.firstName = ""; this.lastName="";this.email="";
        this.cellNumber = ""; this.permissions = ""; this.middleName="";
        this.companyName = ""; this.companyAddress = ""; this.contactName =""; this.contactNumber = "";
        this.isThirdpartyEmployee = false;
        this.isErrorContactNumber = false; this.isErrorCompanyAndContactName = false
    }

    static nonStaffUserPermissions() {
        return ["view sales entry", "view schedule", "view report", "add vendor", "view vendor"]
    }

    errorHanderForCompanyAndContactName(e) {
        var regexp = new RegExp('^[a-zA-Z ]+$'),
            test = regexp.test(e);
        this.isErrorCompanyAndContactName = !test ? true : false
    }

    errorHanderForContactNumber(e) {
        var regexp = new RegExp('[0-9]{10}'),
            test = regexp.test(e);
        this.isErrorContactNumber = !test ? true : false
    }

    isEmployeeThirdParty(value) {
        if (value) {
            this.isThirdpartyEmployee = true;
        } else {
            this.isThirdpartyEmployee = false;
            this.companyName = '';
            this.companyAddress = '';
            this.contactName = '';
            this.contactNumber = '';
        }
    }

}

export class RomUser {
    email: string
    token: string
    firstName: string
    middleName?: string
    lastName: string
    dob: string
    gender: string
    address: Address
    cellNumber: string
    homeNumber?: string
    emergencyContact: string
    emergencyContactRelationship: string
    emergencyContactNumber: string
    student: StudentDetail
    employee: EmployeeDetail
}

export class Address {
    address1: string
    streetnumber: string
    address2: string
    city: string
    province: string
    country: string
    postalcode: string
}

export class StudentDetail {

}

export class EmployeeDetail {

}