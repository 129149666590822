import { Injectable } from '@angular/core';
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from '@angular/common/http';
import { sha512 } from 'js-sha512';
import { catchError } from 'rxjs/operators';
import { throwError, Observable } from 'rxjs';
import { AppError } from '../errors/app-error';
import { InvalidLoginError } from '../errors/invalid-login-error';
import { AuthService } from './auth.service';
import { UnauthorisedAccessError } from '../errors/unathorised-access-error';
import { environment } from 'src/environments/environment';
import { CommonUtils } from './common-utils';
import { UserNotAuthorizedToRegisterError } from '../errors/unauthorized-register.error';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private url = environment.api_prefix + '/user';

  constructor(private authService: AuthService, private http: HttpClient) {}

  login(credentials) {
    var payload = new FormData();
    payload.append('email', credentials.email);
    payload.append('password', sha512(credentials.password).toUpperCase());

    return this.http
      .post(this.url + '/login', payload)
      .pipe(catchError(this.handleError));
  }

  inititateNewUserRestaurant(ownerData) {
    var payload = new FormData();

    payload.append('email', ownerData.ownerEmail);
    payload.append('name', ownerData.ownerName);

    return this.http
      .post(
        this.url + '/restaurant/new',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  signupUserRestaurant(userRestaurantData, token) {
    var payload = userRestaurantData;

    return this.http
      .post(this.url + '/restaurant/signup', payload, {
        headers: new HttpHeaders({ Authorization: 'Bearer ' + token }),
      })
      .pipe(catchError(this.handleError));
  }

  signupUser(userData, token) {
    if (userData['request_type'] == 'sign_up') {
      userData.password = sha512(userData.password).toUpperCase();
    }

    var payload = new FormData();
    for (var key of Object.keys(userData)) {
      if (userData[key] != '') {
        if (key == 'availability') {
          payload.append(
            CommonUtils.camelToSnakeCase(key),
            JSON.stringify(userData[key])
          );
        } else {
          payload.append(CommonUtils.camelToSnakeCase(key), userData[key]);
        }
      }
    }
    return this.http
      .post(this.url + '/signup', payload, {
        headers: new HttpHeaders({ Authorization: 'Bearer ' + token }),
      })
      .pipe(catchError(this.handleError));
  }

  inititateUserAddition(userData) {
    var payload = {};

    for (var key of Object.keys(userData)) {
      payload[CommonUtils.camelToSnakeCase(key)] = userData[key];
      }

    payload['restaurant_id'] = this.authService.getActiveRestaurant().objectId;

    return this.http
      .post(
        this.url + '/new',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  updateUserRolePermissions(userData) {
    var payload = userData;

    for (var key of Object.keys(userData)) {
      if (userData[key] != '') {
        payload[CommonUtils.camelToSnakeCase(key)] = userData[key];
      }
    }

    return this.http
      .post(
        this.url + '/update/roles_permissions',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  userListBasedOnLabourCost() {
    var payload = {
      restaurant_id: this.authService.getActiveRestaurant().objectId,
    };

    return this.http
      .post(
        this.url + '/user_list_based_on_labour_cost',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  approveUser(email) {
    var payload = {
      email: email,
      restaurant_id: this.authService.getActiveRestaurant().objectId,
    };

    return this.http
      .post(
        this.url + '/approve',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  getUserInfo(objectId: string) {
    return this.http.get(this.url + "/details/" + objectId, 
        this.authService.getBasicAuthHeaderForAPI());
  }

  getUsersListForFeedBack(userType) {
    var payload = {
      restaurant_id: this.authService.getActiveRestaurant().objectId,
      user_type: userType,
    };

    return this.http
      .post(
        this.url + '/get_users_list_for_feedback',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  approvalFeedbackUserList(userType) {
    var payload = {
      restaurant_id: this.authService.getActiveRestaurant().objectId,
      user_type: userType,
    };

    return this.http
      .post(
        this.url + '/approval_feedback_user_list',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  viewFeedback(email,userType) {
    var payload = {
      restaurant_id: this.authService.getActiveRestaurant().objectId,
      email: email,
      user_type:userType
    };

    return this.http
      .post(
        this.url + '/view_feedback',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }
  
  approvalFeedback(userDetails) {
    var payload = userDetails; 
    
    return this.http
      .post(
        this.url + '/approval_feedback',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  submitFeedBack(feedbackDetails) {
    var payload = feedbackDetails

    return this.http
      .post(
        this.url + '/submit_feedback',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  feedbackUserList(userType) {
    var payload = {
      restaurant_id: this.authService.getActiveRestaurant().objectId,
      user_type: userType
    };

    return this.http
      .post(
        this.url + '/feedback_user_list',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  viewUserAllFeedback(email,userType) {
    var payload = {
      email: email,
      restaurant_id: this.authService.getActiveRestaurant().objectId,
      user_type:userType
    };

    return this.http
      .post(
        this.url + '/view_user_all_feedback',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  deleteInititateUser(userData) {
    var payload = userData

    return this.http
      .post(
        this.url + '/delete_initiated_user',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  updateUserStatus(activeInactiveUser) {
    var payload = activeInactiveUser
    return this.http
      .post(
        this.url + '/user_current_status',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  addStaffAndManagerList(){
    var payload = {
      restaurant_id: this.authService.getActiveRestaurant().objectId
    }
    return this.http
      .post(
        this.url + '/get_staff_and_managers',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  isUserExit(token) {
    var authAPIHeader;
    if (token)
      authAPIHeader = {
        headers: new HttpHeaders({ Authorization: 'Bearer ' + token }),
      };

    return this.http
      .post(
        this.url + '/check_restaurant_user',null,
        authAPIHeader
      )
      .pipe(catchError(this.handleError));
  }

  viewUsers(userObjectIds: string[], token?: string) {
    var payload = {
      object_ids: userObjectIds,
      request_type: 'view_user',
    };

    var authAPIHeader;
    if (token)
      authAPIHeader = {
        headers: new HttpHeaders({ Authorization: 'Bearer ' + token }),
      };
    else authAPIHeader = this.authService.getBasicAuthHeaderForAPI();

    return this.http
      .post(this.url + '/view', payload, authAPIHeader)
      .pipe(catchError(this.handleError));
  }

  getUserImage(email, imageKey): Observable<Blob> {
    var payload = {
      email: email,
      image_key: imageKey,
      restaurant_id: this.authService.getActiveRestaurant().objectId,
    };

    var authAPIHeader = this.authService.getBasicAuthHeaderForAPI();

    return this.http
      .post(this.url + '/image', payload, {
        headers: authAPIHeader.headers,
        responseType: 'blob',
      })
      .pipe(catchError(this.handleError));
  }

  initiateForgotPasswordEmail(userData) {
    var payload = new FormData();

    payload.append('email', userData.email);

    return this.http
      .post(this.url + '/forgot/password', payload)
      .pipe(catchError(this.handleError));
  }

  getApprovedUser(payload) {
    return this.http
      .post(this.url + '/view', payload, this.authService.getBasicAuthHeaderForAPI())
      .pipe(catchError(this.handleError));
  }

  getImageApprovedUser(payload, token) {    
    return this.http
      .post(this.url + '/image', payload, {
        headers: new HttpHeaders({ Authorization: 'Bearer ' + token }),
        responseType: 'blob',
      })
      .pipe(catchError(this.handleError));
  }

  updateApprovedUserStatus(payload, token) {
    return this.http
      .post(this.url + '/approve', payload, {
        headers: new HttpHeaders({ Authorization: 'Bearer ' + token }),
      })
      .pipe(catchError(this.handleError));
  }

  getUserAvailbilityForSchedule() {
    var payload = {
      object_id: this.authService.getActiveRestaurant().objectId,
    };

    return this.http
      .post(
        this.url + '/user_availability',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  changePassword(userData, token) {
    var payload = new FormData();
    payload.append('email', userData.email);
    payload.append('password', sha512(userData.password).toUpperCase());

    return this.http
      .post(this.url + '/forgot/change_password', payload, {
        headers: new HttpHeaders({ Authorization: 'Bearer ' + token }),
      })
      .pipe(catchError(this.handleError));
  }

  requestNewLocation(userRestaurantData) {
    var payload = {
      user: {
        email: this.authService.currentUser.email,
        restaurant_id: this.authService.getActiveRestaurant().objectId,
      },

      restaurant: {
        name: userRestaurantData.name,
        brand_name: userRestaurantData.brandName,
        address: userRestaurantData.address,
        operating_hours: userRestaurantData.operatingHours,
        week_starting_day: userRestaurantData.weekStartingDay,
        time_zone: userRestaurantData.timeZone,
        operating_currency: userRestaurantData.operatingCurrency,
        contact_number: userRestaurantData.contactNumber,
        terms_condition: userRestaurantData.termsCondition,
        payroll_date: userRestaurantData.payrollDate,
        break_time_entry: userRestaurantData.breakTimeEntry,
        payroll_cost: userRestaurantData.payrollCost,
        margin: userRestaurantData.margin,
        max_budget: userRestaurantData.maxBudget,
        min_wage_above_18: userRestaurantData.minWageAbove_18,
        min_wage_below_18: userRestaurantData.minWageBelow_18
      }
    }

    return this.http.post(this.url + "/restaurant/add_location", payload,
      this.authService.getBasicAuthHeaderForAPI())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateUser(userDetails) {
    var payload = new FormData();
    for (var key of Object.keys(userDetails)) {
      if (userDetails[key] != '') {
        if (key == 'availability') {
          payload.append(
            CommonUtils.camelToSnakeCase(key),
            JSON.stringify(userDetails[key])
          );
        } else {
          if (key === 'role_permissions') {
            payload.append(CommonUtils.camelToSnakeCase(key), JSON.stringify(userDetails[key]));
          } else {
            payload.append(CommonUtils.camelToSnakeCase(key), userDetails[key]);
          }
        }
      }
      else{
          payload.append(CommonUtils.camelToSnakeCase(key), userDetails[key]);
      }
    }
    return this.http.post(this.url + "/update_user", payload,
      this.authService.getBasicAuthHeaderForAPI())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateNonStaffUser(nonStaffUserDetails){
    var payload = nonStaffUserDetails
    
    return this.http.post(this.url + "/update_non_staff_user", payload,
      this.authService.getBasicAuthHeaderForAPI())
      .pipe(
        catchError(this.handleError)
      );
  }

  sendRegistrationMail(email: string,role: string) {
    var payload = {
      email: email,
      restaurant_id: this.authService.getActiveRestaurant().objectId,
      role: role
    }

    return this.http.post(this.url + "/resend_mail", payload,
      this.authService.getBasicAuthHeaderForAPI())
      .pipe(
        catchError(this.handleError)
      );
  }

  updateUserLocation(email: string, newLocation: string) {
    var payload = {
        email: email,
        restaurant_id: this.authService.getActiveRestaurant().objectId,
        new_location: newLocation
    }

    return this.http.post(this.url + "/update_user_location", payload, 
    this.authService.getBasicAuthHeaderForAPI())
      .pipe(
        catchError(this.handleError)
      );
  }

  addUserToNewLocation(email: string, newLocation: Array<string>) {
    var payload = {
      email: email,
      restaurant_id: this.authService.getActiveRestaurant().objectId,
      locations: newLocation
  }

  return this.http.post(this.url + "/add_user_to_new_locations", payload, 
  this.authService.getBasicAuthHeaderForAPI())
    .pipe(
      catchError(this.handleError)
    );
  }

  addNonStaffUser(userDetails) {
    var payload = userDetails

    return this.http
      .post(
        this.url + '/new_non_staff_user',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  updatePopeyesAcademyCredentialsStatus(popeyesAcademyCredentialsStatus: object, objectId, notifyUser?: any, username?: string, password?: string, restaurantId?: string) {
    var payload = {
      ...popeyesAcademyCredentialsStatus,
      objectId: objectId,
      restaurant_id: restaurantId?restaurantId:this.authService.getActiveRestaurant().objectId,
      receiver: notifyUser,
      username: username,
      password: password
    };
    console.log(payload)
    return this.http
      .post(
        this.url + '/update_popeyes_academy_credentials_status',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  updatePopeyesAcademyCourseStatus(popeyesAcademyCourseStatus: object, objectId, notifyUser?: string, percentage?: string, lastLoginDate?: string, restaurantId?: string,) {
    var payload = {
      ...popeyesAcademyCourseStatus,
      objectId: objectId,
      restaurant_id: restaurantId?restaurantId:this.authService.getActiveRestaurant().objectId,
      receiver: notifyUser,
      percentage: percentage,
      last_login_date: lastLoginDate
    };

    return this.http
      .post(
        this.url + '/update_popeyes_academy_course_status',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  updateFoodHandlerCertificateStatus(foodHandlerCertificateStatus: boolean, objectId, file, reasonForReject?: string, restaurantId?: string) {
    var payload = new FormData();
    payload.append('food_handler_certificate_status', String(foodHandlerCertificateStatus))
    payload.append('objectId', String(objectId))
    payload.append('restaurant_id', String(this.authService.getActiveRestaurant().objectId))
    payload.append('fhc', file)
    payload.append('reason', reasonForReject)
    if(restaurantId)
      payload.append('restaurant_id', restaurantId)
    else
      payload.append('restaurant_id', String(this.authService.getActiveRestaurant().objectId))

    return this.http
      .post(
        this.url + '/update_food_handler_certificate_status',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  uploadUserProfileStatictics(file, objectId) {
    var payload = new FormData();
    payload.append('objectId', String(objectId))
    payload.append('restaurant_id', String(this.authService.getActiveRestaurant().objectId))
    payload.append('user_profile_statistics', file)

    return this.http
      .post(
        this.url + '/upload_user_statistics',
        payload,
        this.authService.getBasicAuthHeaderForAPI()
      )
      .pipe(catchError(this.handleError));
  }

  extendUserAckTimePeriod(days: any, email: string) {
    var payload = {
      days: String(days),
      email: email,
      restaurant_id: this.authService.getActiveRestaurant().objectId
    }

    return this.http
    .post(
      this.url + '/update/extend_trail_period',
      payload,
      this.authService.getBasicAuthHeaderForAPI()
    )
    .pipe(catchError(this.handleError));

    
  }

  unlockUserProfile(popeyesAcadmeyCredential: boolean, popeyesAcadmeyCourse: boolean, email: string) {
    var payload = {
      popeyes_acadmey_credential: popeyesAcadmeyCredential,
      popeyes_acadmey_course: popeyesAcadmeyCourse,
      email: email,
      restaurant_id: this.authService.getActiveRestaurant().objectId
    }

    return this.http
    .post(
      this.url + '/unlock',
      payload,
      this.authService.getBasicAuthHeaderForAPI()
    )
    .pipe(catchError(this.handleError));

    
  }


  handleError(error: HttpErrorResponse) {
    console.log(error);
    if (error.status === 422) return throwError(new InvalidLoginError(error));
    if (error.status === 403)
      return throwError(new UnauthorisedAccessError(error));
    if (error.status === 500) return throwError(new AppError(error));
    if (error.status === 401)
      return throwError(new UserNotAuthorizedToRegisterError(error));  
    return throwError(new AppError(error));
  }
}
