<div class="">
  <h6 class="text-center" *ngIf="title">{{title}}</h6>
  <div class="table-responsive">
    <table class="table table-hover table-bordered align-middle">
      <thead>
        <tr>
          <th scope="col" style="width: 24%">
            <p class="font-weight-bold">Days</p>
          </th>
          <th scope="col" style="width: 38%">
            <p class="font-weight-bold">Night (00:00 - 10:00)</p>
          </th>
          <th scope="col" style="width: 38%">
            <p class="font-weight-bold">Day (10:00 - 23:59)</p>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of days; let i = index">
          <th scope="row" style="width: 24%">
            <p class="font-weight-bold">{{ item.day | titlecase }}</p>
          </th>
          <td style="width: 38%" (click)="open(dialog, item.day, 'night', i)">
            <p>{{ item.period[0].open }} - {{ item.period[0].close }}</p>
          </td>
          <td style="width: 38%" (click)="open(dialog, item.day, 'day', i)">
            <p>{{ item.period[1].open }} - {{ item.period[1].close }}</p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>

<ng-template #dialog let-data let-ref="dialogRef">
  <nb-card>
    <nb-card-header>
      Operating time for {{ data.day }} {{ data.shift }} shift
    </nb-card-header>
    <nb-card-body>
      <nb-alert *ngIf="invalidPeriodMsg" status="danger">
        {{ invalidPeriodMsg }}
      </nb-alert>
      <div class="form-group row">
        <label for="open" class="label col-sm-5 col-form-label">
          Shift start</label>
        <div class="col-sm-7">
          <input
            nbInput
            fullWidth
            #openTime
            status="primary"
            name="{{ data.day }}open"
            [ngxTimepicker]="start"
            [ngModel]="data.period.open"
            (ngModelChange)="onModelChange()"
            [min]="data.shift === 'night' ? '00:00' : '10:00'"
            [max]="data.shift === 'night' ? '10:00' : '23:59'"
            [format]="24"
            required
            readonly
          />
          <ngx-material-timepicker
            #start
            [editableHintTmpl]="startHint"
          ></ngx-material-timepicker>
          <ng-template #startHint>
            <p>Shift start time</p>
          </ng-template>
        </div>
      </div>

      <div class="form-group row">
        <label for="close" class="label col-sm-5 col-form-label"
          >Shift end</label
        >
        <div class="col-sm-7">
          <input
            nbInput
            fullWidth
            status="primary"
            name="{{ data.day }}end"
            #closeTime
            [ngxTimepicker]="end"
            [ngModel]="data.period.close"
            (ngModelChange)="onModelChange()"
            [min]="data.shift === 'night' ? '00:00' : '10:00'"
            [max]="data.shift === 'night' ? '10:00' : '23:59'"
            [format]="24"
            required
            readonly
          />
          <ngx-material-timepicker
            #end
            [editableHintTmpl]="endHint"
          ></ngx-material-timepicker>
          <ng-template #endHint>
            <p>Shift end time</p>
          </ng-template>
        </div>
      </div>

      <button
        *ngIf="data.period.open && data.period.close"
        nbButton
        fullWidth
        outline
        status="danger"
        (click)="onClickDelete(data.period, ref)"
      >
        Delete shift
      </button>
    </nb-card-body>
    <nb-card-footer class="d-flex justify-content-around">
      <button nbButton ghost fullWidth status="danger" (click)="ref.close()">
        Cancel
      </button>
      <button
        nbButton
        ghost
        fullWidth
        status="primary"
        [disabled]="invalidPeriodMsg ? 'true' : 'false'"
        (click)="
          onSave(
            data.day,
            data.shift === 'night' ? 0 : 1,
            openTime.value,
            closeTime.value,
            ref
          )
        "
      >
        Save
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>
