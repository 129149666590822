import { Component, Input, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { NotificationService } from 'src/app/@core/services/notification.service';
import { UserService } from 'src/app/@core/services/user.service';
import { PdfviewerComponent } from '../pdfviewer/pdfviewer.component';

@Component({
  selector: 'app-notification-dialog',
  templateUrl: './notification-dialog.component.html',
  styleUrls: ['./notification-dialog.component.scss']
})
export class NotificationDialogComponent implements OnInit {
@Input() notification: any
userProfileStatisticsFile: File;

PACUserPassword;
PACUsername;

userProfileStatisticsPercentage;
userProfileStatisticsLastLoginDate;

FHCRejectMessage;
showPassword = true;
PACTimer: string

  constructor(private userService: UserService,
    private dialogService: NbDialogService,
    protected dialogRef: NbDialogRef<NotificationDialogComponent>,
    private notificationService: NotificationService) { }

  ngOnInit(): void {
    if(this.notification.endDate){
      this.pacTimer(new Date(this.notification.endDate))
      
    }
  }

  onClickYes() {
    if(this.notification.typeId === "1"){
      this.updatePACStatus({status: false,received_popeyes_academy_credentials: true})
    }
    else if(this.notification.typeId === '2') {
      this.updatePacStatus({status: false,finished_popeyes_academy_course: true})
    }
  }

  onClickNo() {
    if(this.notification.typeId === "1"){
      this.updatePACStatus({status: false,received_popeyes_academy_credentials: false})
    }
    else if(this.notification.typeId === '2') {
      this.updatePacStatus({status: false,finished_popeyes_academy_course: false})
    }
  }

  private updatePACStatus(status) {
    this.userService.updatePopeyesAcademyCredentialsStatus(status, this.notification.sender,this.notification.receiver, this.PACUsername, this.PACUserPassword, this.notification.restaurantId).subscribe((res) => {
      this.notificationService.changeNotificationActionStatus(this.notification.objectId).subscribe((res)=> {
        this.dismiss();
      })
    })
  }

  private updatePacStatus(status) {
    this.userService.updatePopeyesAcademyCourseStatus(status, this.notification.sender, this.notification.receiver, this.userProfileStatisticsPercentage, this.userProfileStatisticsLastLoginDate, this.notification.restaurantId).subscribe((res) => {
      this.notificationService.changeNotificationActionStatus(this.notification.objectId).subscribe((res)=> {
        this.dismiss();
      })
    })
  }

  dismiss() {
    this.dialogRef.close();
    if(this.notification.typeId === '5'){
      this.updateUserDetails(this.notification.receiver);
    }
    
  }

  fileChangeEvent($event) {
    var file: File = $event.target.files[0];
    this.userProfileStatisticsFile = file;
  }

  uploadUserProfileStatisticsFile() {
    this.userService.uploadUserProfileStatictics(this.userProfileStatisticsFile, this.notification.sender).subscribe((res)=>{
      this.updatePacStatus({status: false,finished_popeyes_academy_course: true})
      
    })
  }

  uploadUserProfileStatistics() {
    this.updatePacStatus({status: false,finished_popeyes_academy_course: false})
  }

  getFHCFile(email, restaurantID) {
    let payload = {
      "email": email,
      "restaurant_id": restaurantID,
      "image_key": "fhc",
    }
    this.userService.getImageApprovedUser(payload, localStorage.getItem("token")).subscribe(data => {
      let pdfSrc = URL.createObjectURL(data);
      this.viewFHCFile(pdfSrc)
    })
  }

  viewFHCFile(pdfSrc: any) {
    this.dialogService.open(PdfviewerComponent, {
      context: {
        pdfSrc: pdfSrc
      },
    });
  }

  updateFHC(status) {
    this.userService.updateFoodHandlerCertificateStatus(status, this.notification.sender, this.notification.receiver, !status?this.FHCRejectMessage:null, this.notification.restaurantId).subscribe((res) => {
      this.notificationService.changeNotificationActionStatus(this.notification.objectId).subscribe((res)=> {
        this.dismiss();
      })
    })
  }

  pacTimer(countdownDays: Date) {
    let days = this.getTimeRemaining(countdownDays);
    console.log(days);
    
    if(days.timeleft < 1000) {
       this.PACTimer = "00:00"
    }
    else {
      const timeinterval = setInterval(() => {
       let t = this.getTimeRemaining(countdownDays);
               this.PACTimer = 'days: ' + t.days  +
                       ' hours: '+ t.hours + 
                       ' minutes: ' + t.minutes +
                       ' seconds: ' + t.seconds;
       if (t.timeleft < 1000) {
       clearInterval(timeinterval);
     }                
      },1000)
    }  
  }

  updateUserDetails(objectId: string) {
    this.userService.getUserInfo(objectId).subscribe(res => {
    localStorage.setItem("activeUser", JSON.stringify(res))
      location.reload()
    })
  }

  getInputType() {
    if (this.showPassword) {
      return 'text';
    }
    return 'password';
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  getTimeRemaining(countDownDate: Date){
    var now = new Date().getTime();
    var timeleft = countDownDate.getTime() - now;
    var days = Math.floor(timeleft / (1000 * 60 * 60 * 24));
    var hours = Math.floor((timeleft % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    var minutes = Math.floor((timeleft % (1000 * 60 * 60)) / (1000 * 60));
    var seconds = Math.floor((timeleft % (1000 * 60)) / 1000);
  
    return {
      timeleft,
      days,
      hours,
      minutes,
      seconds
    };
  }

}
