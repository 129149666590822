import { Pipe, PipeTransform } from '@angular/core';

@Pipe({

  name: 'br'

})

export class brPipe implements PipeTransform {

  transform(value: string): string {

    return value.toString().replace(/,/g, '<br/>');

 }

}