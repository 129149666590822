<div *ngIf="type==='upload'" class="container">
      <nb-icon icon="upload-outline"></nb-icon>
      <input
        type="file"
        #fileDropRef
        id="{{ name }}"
        accept="application/pdf"
        (change)="fileChangeEvent($event)"
      />
      <h5 class="drp-here">
        Drag and drop <span>{{ name }}</span> here
      </h5>
      <p class="text-center"><b>or</b></p>
      <div class="text-center">
        <button
          *ngIf="!fileName"
          nbButton
          type="button"
          hero
          status="primary"
          size="small"
          shape="round"
        >
          Browse for file
        </button>
        <button
          *ngIf="fileName"
          nbButton
          hero
          status="primary"
          size="small"
          shape="round"
        >
          Change file
        </button>
        <p *ngIf="!fileName"><small class="text-muted">Upload PDF only</small></p>
        <p *ngIf="!fileError" class="text-primary">{{ fileName }}</p>
        <p class="text-warning">{{ fileError }}</p>
      </div>
    </div>

    <div *ngIf="type==='change'">
      <input style="display:none"
        type="file"
        #fileDropRef
        id="{{ name }}"
        accept="application/pdf"
        (change)="fileChangeEvent($event)"
      />
      <button nbButton shape="semi-round" appearance="outline" size="tiny" status="success" (click)="fileDropRef.click()">change</button>
      <p *ngIf="!fileError" class="text-primary">{{ fileName }}</p>
      <p class="text-warning">{{ fileError }}</p>
</div>