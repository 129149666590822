import { ModuleWithProviders, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import {
  NbActionsModule,
  NbLayoutModule,
  NbMenuModule,
  NbSearchModule,
  NbSidebarModule,
  NbUserModule,
  NbContextMenuModule,
  NbSelectModule,
  NbIconModule,
  NbThemeModule,
  NbSpinnerModule,
  NbButtonModule,
  NbCardModule,
  NbBadgeModule,
  NbPopoverModule,
  NbListModule,
  NbInputModule,
  NbDatepickerModule,
  NbFormFieldModule,
  NbAlertModule
} from '@nebular/theme';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import { PdfViewerModule } from 'ng2-pdf-viewer';

import {
  FileUploadComponent,
  FooterComponent,
  HeaderComponent,
  PdfviewerComponent,
  SearchInputComponent,
  NotificationDialogComponent,
  OperatingHourComponent
} from './components';

import {
  OnlyNumberDirective,
  FileValidator,
  FileValueAccessor,
  EqualValidator,
} from './directives';

import {
  RestaurantLayoutComponent,
  AugmenifyLayoutComponent,
  AuthLayoutComponent,
} from './layouts';
import { DEFAULT_THEME } from './styles/theme.default';
import { COSMIC_THEME } from './styles/theme.cosmic';
import { CORPORATE_THEME } from './styles/theme.corporate';
import { DARK_THEME } from './styles/theme.dark';
import { brPipe, HourMinutesPipe, SortSchedulePipe } from './pipe';
import { NgxMaskModule } from 'ngx-mask';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';

const NB_MODULES = [
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
  NbSpinnerModule,
  NbButtonModule,
  PdfViewerModule,
  NbCardModule,
  NbBadgeModule,
  NbPopoverModule,
  NbListModule,
  NbInputModule,
  NbFormFieldModule,
  NbDatepickerModule,
  NbAlertModule,
  NgxMaterialTimepickerModule,
    NgxMaskModule.forRoot({
      showMaskTyped: true
    })
];
const DIRECTIVES = [
  OnlyNumberDirective,
  FileValidator,
  FileValueAccessor,
  EqualValidator  
];
const PIPE = [
  HourMinutesPipe,
  brPipe,
  SortSchedulePipe
]
const COMPONENTS = [
  HeaderComponent,
  FooterComponent,
  SearchInputComponent,
  RestaurantLayoutComponent,
  AugmenifyLayoutComponent,
  AuthLayoutComponent,
  FileUploadComponent,
  PdfviewerComponent,
  NotificationDialogComponent,
  OperatingHourComponent
];


@NgModule({
  imports: [CommonModule, FormsModule, ...NB_MODULES],
  exports: [CommonModule, FormsModule, ...COMPONENTS, ...DIRECTIVES, ...PIPE],
  declarations: [...COMPONENTS, ...DIRECTIVES, ...PIPE],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
      providers: [
        ...NbThemeModule.forRoot(
          {
            name: 'default',
          },
          [ DEFAULT_THEME, COSMIC_THEME, CORPORATE_THEME, DARK_THEME ],
        ).providers,
      ],
    };
  }
}
